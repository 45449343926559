import React from 'react'

import './style/arbitration-agreement.scss'

const ArbitrationAgreement = () => (
  <div className="arbitration-agreement">
    <div className="container">
      <section className="policy-agree">
        <div className="row">
          <div className="col-sm-12">
            <h2>WebsiteBuilder Arbitration Agreement</h2>
            <p><b>If you signed up for or purchased Services on or after July 1, 2017, this Arbitration Agreement applies to you.</b><br/>This Arbitration Agreement includes
            an agreement that all claims will be brought only in an individual capacity (and not as a class action or other representative proceeding). Please read it
            carefully. You may opt out of this agreement by following the opt out procedure described in Section 11.  Unless otherwise stated, defined terms in this
            Arbitration Agreement have the meanings provided to them in the WebsiteBuilder Terms of Service.  WebsiteBuilder may modify this Arbitration Agreement at any
            time with appropriate notice.</p>
          </div>
        </div>
      </section>
    </div>

    <div className="container tos">

      <h2 className="h2">WebsiteBuilder.com Arbitration Agreement</h2>

      <div className="row">
        <div className="col-xs-12">
          <ul className="legal">
            <li className="item" id="PROHIBITEDUSES">
              <p><b>Informal Process First.</b>&nbsp;You agree that in the event of any dispute between you and WebsiteBuilder, you will first contact us and make a good
              faith sustained effort to resolve the dispute before resorting to arbitration under these terms.</p>
            </li>

            <li className="item" id="BindingArbitrat">
              <p><b>Binding Arbitration.</b> Any dispute or claim that remains unresolved after the informal dispute resolution described in Section 1 except for
              disputes relating to the infringement of our intellectual property rights or the access or use of the Service in violation of these terms (a “Claim”) or
              Claims seeking more than $10,000 in damages, will be resolved by binding arbitration, rather than in court, provided that you may assert Claims in small
              claims court located in Suffolk County, Massachusetts if your Claims qualify.</p>
            </li>

            <li className="item" id="NoJudgeorJury">
              <p><b>No Judge or Jury.</b> There is no judge or jury in arbitration, and court review of an arbitration award is limited. However, an arbitrator can award
              on an individual basis the same damages and relief as a court (including injunctive and declaratory relief or statutory damages), and must follow these
              terms as a court would.</p>
            </li>

            <li className="item" id="ArbitratorandRu">
              <p><b>Arbitrator and Rules.</b> The arbitration will be conducted before a neutral single arbitrator, whose decision will be final and binding, and the
              arbitral proceedings will be governed by the AAA Commercial Arbitration Rules, Consumer Due Process Protocol, and Supplementary Procedures for Resolution
              of Consumer. Related Disputes. These rules can be found on the AAA website at www.adr.org.</p>
            </li>

            <li className="item" id="StartinganArbit">
              <p><b>Starting an Arbitration.</b> A party who intends to seek arbitration must first send written notice to WebsiteBuilder’s Legal Department of its
              intent to arbitrate ("Notice"). The Notice to WebsiteBuilder should be sent by any of the following means: (i) electronic mail to
              <a href="mailto:legal@WebsiteBuilder.com"> legal@WebsiteBuilder.com</a>; or (ii) sending the Notice by U.S. Postal Service certified mail to WebsiteBuilder,
              Attn: Legal Department, 5335 Gate Pkwy, 2nd Floor, Jacksonville, FL 32256. The Notice must (x) describe the nature and basis of the claim or dispute;
              and (y) set forth the specific relief sought. If we do not reach an agreement to resolve the claim within thirty (30) days after the Notice is received,
              you or WebsiteBuilder may commence an arbitration proceeding.</p>
            </li>

            <li className="item" id="FormatofProceed">
              <p><b>Format of Proceedings.</b> The arbitration will be conducted, at the option of the party seeking relief, by telephone, online, or based solely on written submissions.</p>
            </li>

            <li className="item" id="Fees">
              <p><b>Fees.</b> If you initiate arbitration, your arbitration fees will be limited to the filing fee set forth in the AAA’s Consumer Arbitration Rules.
              Unless the arbitrator finds the arbitration was frivolous or brought for an improper purpose, we will pay all other AAA and arbitrator’s fees and
              expenses.</p>
            </li>

            <li className="item" id="IndividualBasis">
              <p><b>Individual Basis; Jury Trial Waiver.</b> To the fullest extent permitted by applicable law, you and WebsiteBuilder each agree that any proceeding to
              resolve a Claim will be conducted only in the respective party’s individual capacity and not as a plaintiff or class member in any purported class
              consolidated, multiple plaintiff or representative action (“Class Action”). If for any reason a Claim proceeds in court rather than in arbitration, you and
              WebsiteBuilder each waive any right to a jury trial. You and WebsiteBuilder expressly waive any ability to maintain any Class Action in any forum. If the
              Claim is subject to arbitration, the arbitrator will not have authority to combine or aggregate similar claims or conduct any Class Action nor make an
              award to any person or entity not a party to the arbitration. Any claim that all or part of this Class Action Waiver is unenforceable, unconscionable, void
               or voidable may be determined only by a court of competent jurisdiction and not by an arbitrator.  Further, you agree that the arbitrator may not
               consolidate proceedings or more than one person's claims, and may not otherwise preside over any form of a representative or class proceeding, and that if
               this specific proviso is found to be unenforceable, then the entirety of this arbitration clause shall be null and void.  The arbitrator may award
               injunctive relief only in favor of the individual party seeking relief and only to the extent necessary to provide relief warranted by that party's individual claim.</p>
            </li>

            <li className="item" id="Enforcement">
              <p><b>Enforcement.</b> Any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. The United Nations
              Conventions on Contracts for the International Sale of Goods will have no applicability.</p>
            </li>

            <li className="item" id="Invalidity">
              <p><b>Invalidity.</b> If a court of competent jurisdiction finds the foregoing arbitration provisions invalid or inapplicable, you and WebsiteBuilder each
              agree to the exclusive jurisdiction of the Federal and State courts located in Boston, Massachusetts, and you and WebsiteBuilder each agree to submit to
              the exercise of personal jurisdiction of such courts for the purposes of litigating any applicable dispute or claim.</p>
            </li>

            <li className="item" id="OptingOut">
              <p><b>Opting Out.</b> If you do not want to arbitrate disputes with us and you are an individual, you may opt out of this arbitration agreement by sending
              an email to <a href="mailto:legal@WebsiteBuilder.com">legal@WebsiteBuilder.com</a> within thirty (30) days of the first of the date you access or use the
              Service.</p>
            </li>

            <li className="item" id="Confidentiality">
              <p><b>Confidentiality.</b>The parties shall maintain the confidential nature of the arbitration proceeding and any award, including the hearing, except as
              may be necessary to prepare for or conduct the arbitration hearing on the merits, or except as may be necessary in connection with a court application for
              a preliminary remedy, a judicial challenge to an award or its enforcement, or unless otherwise required by law or judicial decision.</p>
            </li>

            <p><span className="small">Last updated: June 14, 2017</span></p>
          </ul>
        </div>
      </div>
    </div>
  </div>
)

export default ArbitrationAgreement
